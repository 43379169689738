import gql from 'graphql-tag';

import { Query } from '@app/core/common/graphql';
import { Scope } from '../models/scope.model';

export class AvailableScopesQuery extends Query<Array<Scope>> {
  constructor() {
    super('scopes', // name
      ['id', 'Name'],
      [], // params,
      {}, // variables
      true, // is client query
      );
  }
}
