import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineHrefDirective } from './bc.inline-href.directive';

import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [
    InlineHrefDirective
  ],
  exports: [
    CommonModule,
    RouterModule,
    InlineHrefDirective
  ]
})
export class InlineHrefModule {  }
