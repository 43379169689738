<ul class="u-unstyled-list error-message" *ngIf="element.errors && element.dirty">

  <li *ngIf="element.errors.minlength">
    <ng-container i18n="error messages">The entered data must be at least </ng-container>
    {{ element.errors.minlength.requiredLength }}
    <ng-container i18n="error messages"> characters long</ng-container>
  </li>

  <li *ngIf="element.errors.maxlength" i18n="error messages">The entered data is too long</li>
  <li *ngIf="element.errors.required" i18n="error messages">This field is required</li>
  <li *ngIf="element.errors.reqNumber" i18n="error messages">You have to include at least one Number</li>
  <li *ngIf="element.errors.reqSpecial" i18n="error messages">You have to include at least one special character</li>
  <li *ngIf="element.errors.reqUpperCase" i18n="error messages">You have to include at least one Upper Case letter</li>
  <li *ngIf="element.errors.reqLowerCase" i18n="error messages">You have to include at least one Lower Case letter</li>
  <li *ngIf="element.errors.confirmPassword" i18n="error messages">Repeated password must be equal to new password</li>
  <li *ngIf="element.errors.wrongPassword" i18n="error messages">The username-password combination you have entered is incorrect</li>
</ul>
