// 3rd party libs
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
// services
import { AuthService } from '@app/core/services/auth.service';

@Injectable()
export class IsFirstLoginGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(router: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.isFirstLogin) {
      this.router.navigate(['/dashboard']);

      return false;
    }

    return true;
  }
}
