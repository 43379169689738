// angular modules
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { environment } from '../../../../environments/environment';

// Import bugsnag-js and bugsnag-angular
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import bugsnag from '@bugsnag/js';
import { Client } from '@bugsnag/browser/dist/types/bugsnag-core';

@Injectable({
  providedIn: 'root'
})
export class BugsnagService {

  client: Client;
  bugSnagHandler: BugsnagErrorHandler;
  user: any;

  constructor() {
   }

   clearUser() {
     this.user = undefined;
   }
  getConfig(user$: Observable<any>) {
    return Observable.create((observer: Subject<any>) => {
      // if we already have intialized the bugsnag session
      // just return the exixting bugsnag instance
      if (this.client && this.user && this.user.sub) {
        observer.next(this.client);
        observer.complete();

        return;
      }

      user$.subscribe((user) => {
        this.user = user;

        // if we already have intialized the bugsnag session
        // but still no new user is present
        // just return the exixting bugsnag instance
        if (!this.user && this.client) {
          observer.next(this.client);
          observer.complete();

          return;
        }

        if (this.client) {

          this.client.user = this.user;
          this.bugSnagHandler = new BugsnagErrorHandler(this.client);

          observer.next(this.client);
          observer.complete();

          return;
        }

        this.client = bugsnag({
          apiKey: environment.BUGSNAG_AKEY,
          appVersion: environment.VERSION,
          releaseStage: environment.ENV,
          notifyReleaseStages: [ 'prd', 'acc', 'tst' ],
          user: this.user
        });
        this.bugSnagHandler = new BugsnagErrorHandler(this.client);
        observer.next(this.client);
        observer.complete();
      });
    });
  }
}
