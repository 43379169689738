import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { UserLoginService } from '@app/shared/services/UserLoginService';
import { BcMessagesManager } from '@app/core/widgets/bc.catch-message/bc.messages-manager.service';

import { REQ_SPECIAL_PATTERN, REQ_LOWER_CASE_PATTERN, EMAIL_PATTERN,
  REQ_UPPER_CASE_PATTERN, REQ_NUMBER_PATTERN, USERNAME_LOGIN_PATTERN } from '@app/shared/validators/custom.validators';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { TokenHelper, UsersGatewayService, LoginInput } from '@blancoservices/bc-auth-web';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

@Component({
  selector: 'bc-login-component',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;

  tempPass = false;
  rememberMe = false;

  emailPattern: any = EMAIL_PATTERN;
  numberPattern = REQ_NUMBER_PATTERN;
  specialsPattern = REQ_SPECIAL_PATTERN;
  lowerCasePattern = REQ_LOWER_CASE_PATTERN;
  upperCasePattern = REQ_UPPER_CASE_PATTERN;
  usernamePattern = USERNAME_LOGIN_PATTERN;

  loginData: any = {
    userName: '',
    password: '',
    rememberMe: false
  };

  loadingVisible = false;

  constructor(
    public bcMessagesManager: BcMessagesManager,
    public router: Router,
    private toastr: ToastrService,
    private usersGateway: UsersGatewayService,
    private route: ActivatedRoute
  ) {

    if (localStorage.getItem('rememberMe') === 'true') {
      this.rememberMe = true;
      this.loginData.userName = localStorage.getItem('username');
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      if (params.username) {
        this.loginData.userName = params.username;
      }
    });

    if (TokenHelper.isAccesTokenValid()) {
      this.router.navigate([UserLoginService.redirectUser()]);
    }
  }

  submit() {
    const form = this.form.instance.validate();
    localStorage.setItem('rememberMe', this.rememberMe.toString());
    if (this.rememberMe) {
      localStorage.setItem('username', this.loginData.username);
    }

    if (!form.isValid) {
      this.toastr.error(this.bcMessagesManager.translations['INVALID_FORM_ERROR']);

      return;
    }

    this.loadingVisible = true;

    this.usersGateway.login(this.loginData)
    .pipe(catchError((response: HttpErrorResponse) => {
      this.loadingVisible = false;
      switch (response.error.errorMessage) {
        case 'Incorrect username or password.':
        case 'User does not exist.':
          this.toastr.error(this.bcMessagesManager.translations['INCORRECT_CRED_ERROR']);
          break;
        case 'New password is required.':
          this.loginData['newPassword'] = '';
          this.loginData[`confirmNewPassword`] = '';
          this.tempPass = true;
          break;
        case 'Invalid attributes given, profile is missing':
          this.toastr.error(this.bcMessagesManager.translations['PROFILE_MISSING_ERROR']);
          break;
        default:
          this.toastr.error(this.bcMessagesManager.translations['UNDEFINED_ERROR']);
      }

      return throwError(response);
    })).subscribe((result: any) => {
        this.loadingVisible = false;
        if (result.mfaType) {
          UserLoginService.savedCognitoSession = {
            userName: this.loginData.userName,
            session: result.session,
            mfaType: result.mfaType
          };
          this.router.navigate(['confirm']);
        } else {
          UserLoginService.redirectProspects(result.idToken.payload, result.refreshToken.token);
          this.router.navigate([UserLoginService.redirectUser()]);
        }
      });
  }

  gotoForgotPassword() {
    this.router.navigate(['/forgot-password']);
  }

  passwordComparison = () => {
    return this.loginData.newPassword;
  }
}
