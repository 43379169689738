import gql from 'graphql-tag';

import { Mutation } from '@app/core/common/graphql';
import { Scope } from '../models/scope.model';

const mutationParams = [{ name: 'scope', type: 'Scope' }];

export class SetCurrentScopeMutation extends Mutation<Scope> {
  constructor(scope: Scope) {
    super('setCurrentScope',
      [],
      mutationParams,
      {scope},
      true
    );
  }
}
