import { Component, OnInit, OnDestroy, ComponentRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';

import gql from 'graphql-tag';
import { UserService } from '@app/shared/services/user.service';
import { environment } from '@env/environment';
import { Subscription, Observable, Subject } from 'rxjs';
import { BcModalComponent } from '@app/core/widgets/bc.modal/bc.modal.component';
import { map, filter, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ViewKycLogsModalComponent } from '../view-kyc-logs-modal/view-kyc-logs-modal.component';
import { BcModalService } from '@app/core/widgets/bc.modal/bc.modal.service';


const FILL_KYC = gql`
  mutation activityResolver(
    $id: String!
    $tenantId: String
    $surveyId: String!
    $productId: String!
    $lang: String!
  ) {
    activityResolver(ActivityInput: {
      id: $id,
      tenantId: $tenantId,
      surveyId: $surveyId,
      productId: $productId,
      action: "FILL",
      language: $lang
    }) {
      response
    }
  }
`;

const SHOW_KYC = gql`
  mutation historyActivityResolver(
    $id: String!
    $tenantId: String
    $productId: String!
    $surveyId: String!
    $lang: String!
  ) {
    historyActivityResolver(HistoryActivityInput: {
      id: $id,
      tenantId: $tenantId,
      productId: $productId,
      surveyId: $surveyId,
      action: "SHOW",
      language: $lang
    }) {
      response
    }
  }
`;

const GET_KYC_ACTIVITIES = gql`
  query kycActivitiesGet($productId: String!) {
    kycActivitiesGet(productId: $productId){
      id
      status
      createdAt
      surveyId
      trigger
      updatedByUserTypeText
    }
  }
`;

const GET_KYC_HISTORY = gql`
  query kycActivityHistoriesGet($productId: String!) {
    kycActivityHistoriesGet(productId: $productId) {
      outcome
      updatedAt
      surveyId
      id
    }
  }
`;

const GET_NOTIFICATIONS = gql`
query($messageStatus: String, $party_id: String!) {
  clientMessagesGet(messageStatus: $messageStatus, party_id: $party_id) {
    messages {
      id
      messageType
      messageDescription
    }
  }
}
`;

@Component({
  selector: 'bc-kyc-overview-modal.bc-new-modal ',
  templateUrl: './kyc-overview-modal.component.html',
  styleUrls: [
    './kyc-overview-modal.component.scss'
  ]
})
export class KycOverviewModalComponent extends BcModalComponent implements OnInit, OnDestroy {
  kycDetails: any;
  settingRenewal: any;
  hasNotification = false;

  history: Array<any>;
  activeKyc: any;
  unsubscribe$ = new Subject();

  showHistory = true;
  productId: string;

  form: FormGroup;
  previouslySubmitted: any;
  startedKyc: boolean;
  submittedWithFlags: boolean;

  constructor(
    private userService: UserService,
    private apollo: Apollo,
    private router: Router,
    private bcModalService: BcModalService,
    private toastr: ToastrService) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.apollo
      .use(environment.GraphQlEndpoints.ProductsEndpoint.Name)
      .watchQuery({
        query: GET_KYC_ACTIVITIES,
        fetchPolicy: 'network-only',
        variables: {
          productId: this.productId
        }
      })
      .valueChanges.pipe(takeUntil(this.unsubscribe$), map((x: any) => x.data.kycActivitiesGet))
      .subscribe((activityResp: any) => {
        const activitiy = activityResp
          .filter((act: any) => act.status === 'STARTED' || act.status === 'CREATED')[0];
        this.activeKyc = activitiy;

        if (this.hasNotification && !this.activeKyc) {
          this.activeKyc =  activityResp
          .filter((act: any) => act.status === 'NOT_STARTED')[0];
        }

        if (!this.activeKyc) {
          this.activeKyc = activityResp
          .filter((act: any) => act.status === 'SUBMITTED_WITH_FLAGS')[0];
          this.submittedWithFlags =  this.activeKyc !== undefined;
        }

        this.startedKyc = activitiy && activitiy.status === 'STARTED';
      });
      this.checkActiveKyc();

    this.apollo
      .use(environment.GraphQlEndpoints.ProductsEndpoint.Name)
      .watchQuery({
        query: GET_KYC_HISTORY,
        fetchPolicy: 'network-only',
        variables: {
          productId: this.productId
        }
      })
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        map((history: any) => history.data.kycActivityHistoriesGet)
      )
      .subscribe((history: any) => {
        this.history = history.filter((kyc: any) => kyc.outcome !== 'SKIPPED' && kyc.outcome !== 'CANCELLED');
        this.previouslySubmitted = this.history
          .filter((kyc: any) => kyc.outcome === 'SUBMITTED' || kyc.outcome === 'ACCEPTED')[0];
      });
      this.checkKycNotification();
  }
  checkActiveKyc() {

    this.apollo
      .use(environment.GraphQlEndpoints.ProductsEndpoint.Name)
      .watchQuery({
        query: GET_KYC_ACTIVITIES,
        fetchPolicy: 'network-only',
        variables: {
          productId: this.productId
        }
      })
      .valueChanges.pipe(takeUntil(this.unsubscribe$), map((x: any) => x.data.kycActivitiesGet))
      .subscribe((activityResp: any) => {
        const activitiy = activityResp
          .filter((act: any) => act.status === 'STARTED' || act.status === 'CREATED')[0];
        this.activeKyc = activitiy;

        if (this.hasNotification && !this.activeKyc) {
          this.activeKyc =  activityResp
          .filter((act: any) => act.status === 'NOT_STARTED')[0];
        }

        if (!this.activeKyc) {
          this.activeKyc = activityResp
          .filter((act: any) => act.status === 'SUBMITTED_WITH_FLAGS')[0];
          this.submittedWithFlags =  this.activeKyc !== undefined;
        }

        this.startedKyc = activitiy && activitiy.status === 'STARTED';
      });
  }
  checkKycNotification() {
    const party_id = this.userService.user.partyId;
    const messageStatus = 'Unread';
    this.apollo
      .use(environment.GraphQlEndpoints.NotificationsEndpoint.Name)
      .watchQuery({
        query: GET_NOTIFICATIONS,
        variables: { messageStatus, party_id },
        fetchPolicy: 'network-only'
      })
      .valueChanges
      .pipe(takeUntil(this.unsubscribe$),
      map((notifications: any) =>
        notifications.data.clientMessagesGet.messages
          .filter(not =>
              not.messageType === 'KYC Invitation'
              || not.messageType === 'KYC Renewal'
              )[0]
      )).subscribe(notification => {
        this.hasNotification = notification;
        this.checkActiveKyc();
      });
  }


  reviewLast() {
    // this.router.navigate([`/dashboard/kyc/${this.productId}`]);
    // this.close.emit();

    this.router.navigate(['/', 'dashboard', 'kyc'], {queryParams: {
      id: this.previouslySubmitted.id,
      surveyId: this.previouslySubmitted.surveyId,
      productId: this.productId
    }});
    this.close.emit();
  }

  start() {
    this.close.emit(this.activeKyc);
  }

  continue() {
    this.router.navigate(['/', 'dashboard', 'kyc'], {queryParams: {
      id: this.activeKyc.id,
      surveyId: this.activeKyc.surveyId,
      productId: this.productId
    }});
    this.close.emit();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  hasActiveKycAndSubmitted = () => this.previouslySubmitted && this.activeKyc && !this.startedKyc && !this.submittedWithFlags;
  hasNewKyc = () => !this.previouslySubmitted && this.activeKyc && !this.startedKyc && !this.submittedWithFlags;
  hasNoActiveKycOnlySubmitted = () => this.previouslySubmitted && !this.activeKyc  && !this.submittedWithFlags;

  openKYC({ data }) {
    this.router.navigate(['/', 'dashboard', 'kyc'], {queryParams: {
      id: data.id,
      surveyId: data.surveyId,
      productId: this.productId
    }});
    this.close.emit();
  }

  viewStatus({ data }) {
    const modal = (<ComponentRef<ViewKycLogsModalComponent>> this.bcModalService.openModalBox(
      ViewKycLogsModalComponent
    ));

    modal.instance.productId = this.productId;
    modal.instance.kycDetails = data;

    modal.instance.close.subscribe(() => {
      modal.destroy();
    });
  }
}
