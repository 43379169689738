// 3rd party libs
import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

  getLocalStorage(): any {
    const storage = window.localStorage || localStorage;
    if (!localStorage) {
      throw new Error('Browser does not support local storage');
    }

    return storage;
  }

  setValue(key: string, value: string): void {
    this.getLocalStorage().setItem(key, value);
  }

  getValue(key: string): string {
    return this.getLocalStorage().getItem(key);
  }

  removeValue(key: string): void {
    this.getLocalStorage().removeItem(key);
  }
}
