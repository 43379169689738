<div class="modal-dialog modal-dialog_large">
  <div class="modal-content">
    <div class="modal-header">
      <button (click)="onClose($event)" type="button" class="close u-unstyled-btn">
        <svg class="svg-icon" width="21" height="21">
          <use bcInlineHref="#remove-icon"></use>
        </svg>
      </button>
      <h3 class="modal-title" i18n="@@overviewKyc">Overview Know your Customer questionnaires</h3>
    </div>

    <div class="modal-body">
      <div class="bc-icon">
        <svg *ngIf="hasNewKyc() || hasNoActiveKycOnlySubmitted() || submittedWithFlags" class="bc-icon" width="60" height="79">
          <use bcInlineHref="#survey-icon"></use>
        </svg>
        <svg *ngIf="startedKyc" class="bc-icon" width="60" height="79">
          <use bcInlineHref="#kyc-continue-icon"></use>
        </svg>
        <svg *ngIf="hasActiveKycAndSubmitted()" class="bc-icon" width="60" height="79">
          <use bcInlineHref="#kyc-waiting-icon"></use>
        </svg>
      </div>

      <h4 *ngIf="hasActiveKycAndSubmitted()" class="h3-header h3-header_charcoal" i18n="@@updateKycQuestionaire">
        It is time to update your KYC questionnaire to your current situation. View your last submitted questionnaire, or click start to get started.
      </h4>
      <h4 *ngIf="hasNewKyc()" class="h3-header h3-header_charcoal" i18n="@@waitingKycQuestionaire">
        A new KYC questionnaire is waiting for you.
      </h4>
      <h4 *ngIf="startedKyc" class="h3-header h3-header_charcoal">
        <ng-container i18n="@@alreadyStartedKyc">
          You already started filling out the KYC questionnaire.
        </ng-container> <br />
        <ng-container i18n="@@clickToContinue">
          Click here to continue.
        </ng-container>
      </h4>
      <h4 *ngIf="hasNoActiveKycOnlySubmitted()" class="h3-header h3-header_charcoal">
        <ng-container i18n="@@lastUpdatedKyc">
          Your last KYC questionnaire was submitted on
        </ng-container> {{ previouslySubmitted.updatedAt | date: "shortDate"}}.
      </h4>
      <h4 *ngIf="submittedWithFlags" class="h3-header h3-header_charcoal" i18n="@@kycproccessing">
        We now will process your KYC questionnaire
      </h4>

      <div class="modal-footer justify-buttons">
        <button *ngIf="hasNoActiveKycOnlySubmitted()" class="btn bc-btn bc-btn_default"
          i18n="@@view" type="button" (click)="reviewLast()">View</button>
          <button *ngIf="submittedWithFlags" class="btn bc-btn bc-btn_default"
            i18n="@@view" type="button" (click)="continue()">View</button>
        <button *ngIf="hasActiveKycAndSubmitted()" class="btn bc-btn bc-btn_default"
          i18n="@@review" type="button" (click)="reviewLast()">Review</button>
        <button *ngIf="activeKyc && !startedKyc && !submittedWithFlags" class="btn bc-btn bc-btn_custom " i18n="@@start" type="button"
          (click)="start()">Start</button>
          <button *ngIf="startedKyc" class="btn bc-btn bc-btn_custom" i18n="@@continue" type="button"
            (click)="continue()">Continue</button>
      </div>

      <hr *ngIf="history && history.length" />
      <div class="history" *ngIf="history && history.length">
        <span class="history-title" i18n="@@history">History</span>
        <span class="history-arrow" (click)="showHistory = !showHistory">
          <svg width="14" height="8" [ngClass]="{ 'open' :  showHistory}">
            <use x="0" xlink:href="#arrowMainMenu" y="0"></use>
          </svg>
        </span>
      </div>

      <div class="modal-grid justify-buttons" *ngIf="history && history.length && showHistory">
        <dx-data-grid id="history" [dataSource]="history" [columnResizingMode]="'widget'" [allowColumnResizing]="true"
          [allowColumnReordering]="true" [showRowLines]="true">

          <dxi-column dataField="outcome" cellTemplate="outcomeTemplate" [minWidth]="155" caption="Outcome"
            i18n-caption="@@outcome"></dxi-column>
          <dxi-column dataField="updatedAt" sortOrder="desc" dataType="date" width="125" caption="Date" 
          [format]="{ type: 'dd-MM-y' }" i18n-caption="@@date"></dxi-column>

          <dxi-column dataField="surveyId" cellTemplate="actionsTemplate" cssClass="actions-column" width="75" caption="Action"
            i18n-caption="@@action"></dxi-column>

          <div *dxTemplate="let d of 'actionsTemplate'">
            <div class="table-actions-wrap" *ngIf="d.data.outcome !== 'ACCOUNT_CREATED'">
              <div class="trigger">
                <span></span>
              </div>
              <ul class="list u-unstyled-list">
                <li *ngIf="d.data.outcome == 'SUBMITTED' || d.data.outcome == 'ACCEPTED'">
                  <button type="button" (click)="openKYC(d)" class="link" i18n>Show KYC</button>
                </li>
                <li *ngIf="d.data.outcome == 'SUBMITTED' || d.data.outcome == 'NO_CHANGE' || d.data.outcome == 'ACCEPTED'" >
                  <button type="button" (click)="viewStatus(d)" class="link" i18n>View status details</button>
                </li>
              </ul>
            </div>
          </div>

          <div *dxTemplate="let d of 'outcomeTemplate'">
            <ng-container [ngSwitch]="d.data.outcome">
              <ng-template [ngSwitchCase]="'NO_CHANGE'" i18n>Nothing has changed since last KYC</ng-template>
              <ng-template [ngSwitchCase]="'SUBMITTED'" i18n>Submitted</ng-template>
              <ng-template [ngSwitchCase]="'ACCEPTED'" i18n>Submitted</ng-template>
              <ng-template [ngSwitchCase]="'SUBMITTED_WITH_FLAGS'" i18n>Submitted</ng-template>
              <ng-template [ngSwitchCase]="'ACCOUNT_CREATED'" i18n="@@accountCreated">Account created</ng-template>
              <ng-template [ngSwitchCase]="'CANCELLED'" i18n="@@cancelled">Cancelled</ng-template>
              <ng-template ngSwitchDefault>{{d.data.outcome}}</ng-template>
            </ng-container>
          </div>
        </dx-data-grid>
      </div>
    </div>
  </div>
