<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <button (click)="onClose($event)" type="button" class="close pull-right u-unstyled-btn icon-link h4-header h4-header_charcoal">
        <svg class="icon-link__icon svg-icon" width="14" height="14">
          <use bcInlineHref="#remove-icon"></use>
        </svg>
        <ng-container i18n="@@close">Close</ng-container>
      </button>
      <h3 class="modal-title">I'm modal</h3>
    </div>

    <div class="modal-body">

    </div>

    <div class="modal-footer">
      <button class="btn bc-btn bc-btn_default"
              (click)="onClose($event)" i18n>Cancel</button>
    </div>
  </div>
</div>
