interface Date {
  addDays(days: number): Date;
}

Date.prototype.addDays = function(days: number): Date {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  date.setHours(0, 0, 0, 0);

  return date;
};
