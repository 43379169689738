// 3rd party libs
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';

// components
import { AppComponent } from './app.component';
// modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';

import { ToastNoAnimationModule, ToastrModule, ToastNoAnimation } from 'ngx-toastr';
import { PipeModule } from '@app/shared/pipes/pipe.module';
import { AuthModule } from '@app/core/components/auth/auth.module';
import { BcBugSnagModule } from './core/common/bugsnag/bugsnag.module';
import { ProductInterceptor } from '@core/interceptors/product.interceptor';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { registerLocaleData } from '@angular/common';
import { BcAuthWebModule } from '@blancoservices/bc-auth-web';
import { environment } from '@env/environment';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';

registerLocaleData(localeFr, 'fr-FR');
registerLocaleData(localeNl, 'nl-NL');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BcBugSnagModule,
    // Toastr
    ToastNoAnimationModule,
    ToastrModule.forRoot({
      toastComponent: ToastNoAnimation,
    }),
    AppRoutingModule,
    HttpClientModule,
    BcAuthWebModule.forRoot(environment),
    AuthModule,
    CoreModule,
    PipeModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ProductInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
