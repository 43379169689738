import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { BsasOperation } from '@core/services/operations';

@Injectable()
export class ProductInterceptor implements HttpInterceptor {
  private bsasEndpoint = environment.GraphQlEndpoints.BsasEndpoint.Url;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req.clone();

    if (this.bsasEndpoint === request.url) {
      const bsasOperations = Object.values(BsasOperation);
      const currentOperation = req.body ? req.body.operationName : '';

      if (bsasOperations.includes(currentOperation)) {
        request = req.clone({
          headers: request.headers
          .set('X-Service-Type', this.serviceTypes[currentOperation])
        });
      }
    }

    return next.handle(request);
  }

  private get serviceTypes() {
    const serviceTypes = {};
    serviceTypes[BsasOperation.GetProductPerformance] = 'R';
    serviceTypes[BsasOperation.GetProductPortfolio] = 'R';

    return serviceTypes;
  }
}
