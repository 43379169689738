import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { UserLoginService } from '@app/shared/services/UserLoginService';
import { BcMessagesManager } from '@app/core/widgets/bc.catch-message/bc.messages-manager.service';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { UsersGatewayService } from '@blancoservices/bc-auth-web';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';


@Component({
  selector: 'bc-confirm-code-component',
  templateUrl: 'confirm.component.html',
  styleUrls: ['confirm.component.scss']
})
export class ConfirmCodeComponent implements OnInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;

  username: string;
  confirmData = {
    code: ''
  };

  loadingVisible = false;

  constructor(
    public bcMessagesManager: BcMessagesManager,
    public router: Router,
    private route: ActivatedRoute,
    private usersGateway: UsersGatewayService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      this.username = params['username'];
    });
  }

  // form validation
  submit(): void {
    const form = this.form.instance.validate();
    if (form.isValid) {
      this.submitForm();
    }
  }

  submitForm() {
    const timeOut = 3000;
    this.loadingVisible = true;
    this.usersGateway.completeMFAChallenge({
      ...UserLoginService.savedCognitoSession,
      mfaCode: this.confirmData.code
    }).pipe(catchError((response: HttpErrorResponse) => {

      const errorType = response.error.errorType,
            message = response.error.errorMessage;
      switch (errorType) {
        case 'CodeMismatchException':
          this.toastr.error(this.bcMessagesManager.translations['INVALID_CODE']);
          break;
        case 'NotAuthorizedException':
            this.toastr.error(this.bcMessagesManager.translations['EXPIRED_CODE']);
            setTimeout(() => {
              this.router.navigate([UserLoginService.redirectUser()]);
            }, timeOut);
          break;
        default:
            this.toastr.error(this.bcMessagesManager.translations['UNDEFINED_ERROR']);
            setTimeout(() => {
              this.router.navigate(['login']);
            }, timeOut);
      }

      return throwError(response);
    }))
      .subscribe((result: any) => {
        UserLoginService.redirectProspects(result.idToken.payload, result.refreshToken.token);
        this.router.navigate([UserLoginService.redirectUser()]);
      });
  }
}
