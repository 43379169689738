import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';


@Directive({selector: '[bcInlineHref]'})
export class InlineHrefDirective implements OnInit {
  @Input() bcInlineHref: string;

  private static readonly HREF_ATTR: string = 'href';
  private static readonly SVG_USE_TAG: string = 'use';
  private static readonly XLINK_NS: string = 'http://www.w3.org/1999/xlink';

  constructor(private element: ElementRef,
              private router: Router) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.replaceHref();
      });
  }

  ngOnInit(): void {
    this.replaceHref();
  }

  private replaceHref() {
    const fixedHref = window.location.href.replace(window.location.hash, '') + this.bcInlineHref;

    if (this.element.nativeElement.tagName === InlineHrefDirective.SVG_USE_TAG) {
      this.element.nativeElement.setAttributeNS(InlineHrefDirective.XLINK_NS, InlineHrefDirective.HREF_ATTR, fixedHref);
    } else {
      this.element.nativeElement.href = fixedHref;
    }
  }
}
