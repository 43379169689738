import { Injector, Injectable, ComponentRef,
    ComponentFactoryResolver, ViewContainerRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { ConfirmationModalComponent } from './predefined/confirmation/bc.modal.confirmation.component';

// TODO Volodymyr Logvinov make refactoring to reduce cuplling
@Injectable()
export class BcModalService {
  modalOutlets: Array<ViewContainerRef>;

  openModal = new Subject<any>();
  openModalEmitted$ = this.openModal.asObservable();

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    this.modalOutlets = [];
  }

  openModalBox(component: any, index: number = 0): ComponentRef<{}> {
    this.modalOutlets[index].clear();

    const modalComponentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const modalComponentRef = this.modalOutlets[index].createComponent(modalComponentFactory);

    this.openModal.next({
      index: index,
      status: true
    });

    return modalComponentRef;
  }

  openModalBoxWithInjector(component: any, index: number = 0,
                           componentFactoryResolver: ComponentFactoryResolver,
                           injector: Injector): ComponentRef<{}> {
    this.modalOutlets[index].clear();

    const modalComponentFactory = componentFactoryResolver.resolveComponentFactory(component);
    const refInjector = Injector.create({
          providers: [{provide: component, useValue: component}],
          parent: injector
      });
    const modalComponentRef = this.modalOutlets[index].createComponent(modalComponentFactory, index, refInjector);

    this.openModal.next(
      {
        index: index,
        status: true
      }
    );

    return modalComponentRef;
  }

  openConfirmationBox(questions: Array<string>, title?: string): ComponentRef<{}> {
    this.modalOutlets[0].clear();

    const modalComponentFactory = this.componentFactoryResolver.resolveComponentFactory(ConfirmationModalComponent);
    const modalComponentRef = this.modalOutlets[0].createComponent(modalComponentFactory);

    modalComponentRef.instance.title = title || '';
    modalComponentRef.instance.questions = questions;

    return modalComponentRef;
  }

  add(modalOutlet: ViewContainerRef): number {
    return this.modalOutlets.push(modalOutlet) - 1;
  }

  set(modalOutlet: ViewContainerRef, index: number = 0): void {
    this.modalOutlets[index] = modalOutlet;
  }

  close(modal: any, index: number) {
    if (index !== undefined) {
      this.openModal.next(
        {
          index: index,
          status: false
        }
      );
    }

    modal.destroy();
  }

}
