import { Injectable } from '@angular/core';
import { Router, Resolve } from '@angular/router';
import { UserService, User } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class UserGuardService implements Resolve<User> {
  user: User;

  constructor(
    private userService: UserService, private router: Router
  ) {  }

  resolve(): Promise<User> {
    if (this.userService.user && this.userService.user.username) {
      this.userService.userDetails$.next(this.user);

      return Promise.resolve(this.userService.user);
    }

    return this.userService.updateUser()
      .then((user: User) => {
        this.user = user;
        this.userService.userDetails$.next(this.user);

        return user;
      })
      .catch(() => {
        this.router.navigate(['login']);

        return Promise.reject({});
      });
  }
}
