import { Component, Inject } from '@angular/core';
import { LOCALES_MANAGER, LEI_STATUS_TRANSLATIONS_MANAGER } from './translation.injectors';
import { BcMessagesManager } from '../widgets/bc.catch-message/bc.messages-manager.service';

@Component({
  selector: 'bc-translations',
  templateUrl: 'translations.component.html'
})
export class TranslationsComponent {
  constructor(
    @Inject(LOCALES_MANAGER) public bcLocalesManager: BcMessagesManager,
    @Inject(LEI_STATUS_TRANSLATIONS_MANAGER) public leiStatusesManager: BcMessagesManager,
  ) { }
}
