export enum GraphQLParameterType {
  Json = 'JSON',
  SequelizeJson = 'SequelizeJSON',
  Int = 'Int',
  Float = 'Float',
  String = 'String',
  Boolean = 'Boolean',
  Date = 'Date',
  RequiredInt = 'Int!',
  RequiredFloat = 'Float!',
  RequiredString = 'String!',
  RequiredBoolean = 'Boolean!',
  RequiredDate = 'Date!',
  RequiredIntArray = '[Int]!',
  RequiredIntInRequiredArray = '[Int!]!',
  RequiredProductPerformance = 'ProductPerformanceInput!',
  RequiredProductPortfolio = 'ProductPortfolioInput!',
}
