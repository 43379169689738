// 3rd party libs
import { NgModule } from '@angular/core';
// modules
import { CommonServicesModule } from './modules/common-services.module';
// guards
import { AfterSignUpFormGuard } from '@app/core/guards/after-sign-up-form.guard';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { IsFirstLoginGuard } from '@app/core/guards/is-first-login.guard';
import { IsAuthorizedGuard } from '@app/core/guards/is-authorized.guard';
import { ServicesModule } from '@app/core/services';
import { PipeModule } from '@app/shared/pipes/pipe.module';
import { ModalsModule } from './modals/modals.module';
import { BcModalModule } from './widgets/bc.modal/bc.modal.module';
import { BcBugSnagModule } from './common/bugsnag/bugsnag.module';
import { TranslationsModule } from './translations/translations.module';

@NgModule({
  imports: [
    CommonServicesModule,
    TranslationsModule,
    ServicesModule,
    ModalsModule,
    PipeModule,
    BcModalModule,
    BcBugSnagModule
  ],
  exports: [
    TranslationsModule
  ],
  providers: [
    AuthGuard,
    AfterSignUpFormGuard,
    IsFirstLoginGuard,
    IsAuthorizedGuard
  ]
})

export class CoreModule {}
