
// 3rd party libs
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenHelper } from '@blancoservices/bc-auth-web';


@Injectable()
export class IsAuthorizedGuard implements CanActivate {

  constructor(
    private router: Router,
  ) {}

  canActivate(router: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!TokenHelper.isAccesTokenValid()) {
      return true;
    }
    this.router.navigate(['/dashboard']);

    return false;
  }

}
