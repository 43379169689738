// 3rd party libs
import { Injectable } from '@angular/core';
import { Subscription } from 'apollo-client/util/Observable';
import { Observable, merge, fromEvent } from 'rxjs';

// services
import { ApolloService } from './apollo.service';
import { ToastrService } from 'ngx-toastr';
import { TokenHelper } from '@blancoservices/bc-auth-web';
import { User } from '@app/shared/models/user';
import { UserLoginService } from '@app/shared/services/UserLoginService';

@Injectable()
export class AuthService {
  user: User;
  isFirstLogin: boolean;
  allEvents$: Observable<any>;
  checkUserActivitySubscription: Subscription;

  checkActivityUser = false;
  normalTimeToTokenUpdate = 180000;
  beforeEndTimeToTokenUpdate = 0;
  currentTimeToTokenUpdate: number;
  startSetInterval = null;
  timeout = null;

  constructor(
    private apolloService: ApolloService,
    private userLoginService: UserLoginService,
    private toastr: ToastrService
  ) {
    this.isFirstLogin = true;
    this.apolloService.configureEndpoints();

    this.currentTimeToTokenUpdate = this.normalTimeToTokenUpdate;
    this.allEvents$ = merge(
      fromEvent(document, 'click'),
      fromEvent(document, 'keyup')
    );
  }

  stop() {
    this.userLoginService.logout().subscribe(() => clearInterval(this.startSetInterval));
  }

  // start counter down time expiration session
  expirationSessionStatusStart() {
    if (this.startSetInterval) {
      return;
    }
    const timeBeforExpiration = 300;
    const interval = 1000;
    const timeToExpiring =
      this.user.exp - Math.floor(new Date().getTime() / interval);
    // start counter
    this.startSetInterval = setInterval(() => {
      // check if access token exist or time of token has expired
      if (
        !TokenHelper.isAccesTokenValid() ||
        new Date() > new Date(this.user.exp * interval)
      ) {
        this.stop();
      }
      // begin checking user activities before 5 minutes to expiring token
      if (timeToExpiring < timeBeforExpiration && !this.checkActivityUser) {
        this.currentTimeToTokenUpdate = this.beforeEndTimeToTokenUpdate;
        this.checkActivityUser = true;
        this.sessionCompletionNotification();
      }
      // loguot if user don't have any activities
      if (timeToExpiring < 0) {
        this.stop();
      }
    }, interval);
  }

  hasSessionExpired() {
    const timeBeforExpiration = 300;
    const interval = 1000;
    const timeToExpiring =
      this.user.exp - Math.floor(new Date().getTime() / interval);

    // check if access token exist or time of token has expired
    if (
      !TokenHelper.isAccesTokenValid() ||
      new Date() > new Date(this.user.exp * interval)
    ) {
      this.stop();

      return true;
    }

    // loguot if user don't have any activities
    if (timeToExpiring < 0) {
      this.stop();

      return true;
    }
  }

  // stop counter down time expiration session
  expirationSessionStatusStop() {
    clearInterval(this.startSetInterval);
    this.startSetInterval = null;
  }

  // checking user's activity (click, keyup) and refreshing token after last user's activity
  checkUserActivity() {
    if (this.checkUserActivitySubscription) {
      return;
    }
    this.checkUserActivitySubscription = this.allEvents$.subscribe(event => {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {}, this.currentTimeToTokenUpdate);
    });
  }

  // show notification about ending session and awaiting for user's activity
  sessionCompletionNotification() {
    this.toastr.warning(
      'You are going to be logged out in less than 5 minutes'
    );
  }

  // TODO to independent service or widget (may be :))
  showNotify(type) {
    const displayTime = 600;
    this.toastr.error('Some error occured, please try refresh.');
  }
}
