<bc-catch-messages [manager]="bcMessagesManager">
  <bc-message key="START_KYC_ERROR" i18n>Something went wrong while updating KYC. Try again. If the problem persists, please contact support.</bc-message>
  <bc-message key="SKIP_KYC_ERROR" i18n>We were not able to process your skipped KYC questionnaire. Please try again. If the problem persists, contact support.</bc-message>

  <bc-message key="START_KYC" i18n="@@startKyc">Start KYC</bc-message>
  <bc-message key="SKIP_KYC" i18n="@@skipKyc">Skip KYC</bc-message>
</bc-catch-messages>

<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <button (click)="onClose($event)" type="button"
              class="close u-unstyled-btn">
        <svg class="svg-icon" width="21" height="21">
          <use bcInlineHref="#remove-icon"></use>
        </svg>
      </button>
      <h3 class="modal-title" i18n>Start KYC questionnaire</h3>
    </div>
    <form [formGroup]="form" novalidate>
      <div class="modal-body">
        <h4 class="h3-header h3-header_charcoal h3-header_regular" i18n>What do you want to do?</h4>

        <div class="radio__field">
          <input type="radio"
                 id="fillKYC"
                 [value]="true"
                 formControlName="fillKYC">
          <label class="radio__label"
                 for="fillKYC" i18n>Fill KYC questionnaire</label>
          <span class="radio__check"></span>
        </div>
        <div class="radio__field">
          <input type="radio"
                 id="nothingChanged"
                 [value]="false"
                 formControlName="fillKYC">
          <label class="radio__label"
                 for="nothingChanged" i18n>Situation has not change since last KYC questionnaire</label>
          <span class="radio__check"></span>
        </div>
        <bc-messages [element]="form.get('fillKYC')"></bc-messages>


        <div class="field__item">
          <label class="field__label field__label_bolder"
                 for="explanation" i18n="@@explanation">Explanation</label>
          <textarea class="text_field"
                    name="explanation"
                    id="explanation"
                    placeholder="Write here"
                    formControlName="explanation"></textarea>
          <bc-messages [element]="form.get('explanation')"></bc-messages>
        </div>
      </div>

      <div class="modal-footer justify-buttons">
        <button class="btn bc-btn bc-btn_default bc-btn_block"
                i18n="@@cancel" type="button"
                (click)="onClose($event)">Cancel</button>
        <button class="btn bc-btn bc-btn_custom bc-btn_block"
                i18n type="submit" [disabled]="form.invalid"
                (click)="submit()">Next</button>
      </div>
    </form>
  </div>
</div>
