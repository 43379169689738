<ul class="nav">
  <li>
    <h5 i18n="@@currentLocale">
      English
    </h5>
    <ul class="sub-nav">
      <ng-container *ngFor="let l of locales">
        <li *ngIf="locale !== l.value">
          <button type="button" class="u-unstyled-btn sub-menu__link" (click)="switchLocale(l.value)">{{ l.name }}</button>
        </li>
      </ng-container>
    </ul>
  </li>
</ul>
