import { CombinableQuery } from '@app/core/common/graphql';
import { CurrentScopeQuery, AvailableScopesQuery } from '../queries';

export const availableScopesQuery = new AvailableScopesQuery();
export const currentScopeQuery = new CurrentScopeQuery();

export const setCurrentScopeResolver = {
  Mutation: {
    setCurrentScope: (_, { scope }, { cache }) => {
      const data: any = {
        currentScope: {
          __typename: 'Scope',
          ...scope
        }
      };

      const scopesConfigQuery = new CombinableQuery('scopesConfig', availableScopesQuery, currentScopeQuery);
      const previousState = cache.readQuery({
        query: scopesConfigQuery.query
      });

      data.scopes = availableScopesQuery.extractResult(previousState)
        .filter(availableScope => availableScope.id !== scope.id)
        .concat([currentScopeQuery.extractResult(previousState)]);

      cache.writeData({ data });

      return data;
    },
  },
  defaults: {
    currentScope: { __typename: 'Scope', id: 'qwerty', Name: 'Default' },
  },
};
