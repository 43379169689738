import { Component, EventEmitter } from '@angular/core';
import { UsersGatewayService, MFAType } from '@blancoservices/bc-auth-web';
import { catchError } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { BcModalComponent } from '@app/core/widgets/bc.modal/bc.modal.component';
import { UserService } from '@app/shared/services/user.service';
import { LoggerService } from '@app/shared/services/logger.service';

@Component({
  selector: 'bc-software-mfa-register-modal.bc-new-modal',
  templateUrl: './software-mfa-register-modal.component.html',
  styleUrls: [
    './software-mfa-register-modal.component.scss'
  ]
})
export class SoftwareMfaRegisterModalComponent extends BcModalComponent {
  agree = new EventEmitter();
  showMfaButton;
  showQrCode;
  qrCode: string;
  showError: boolean;
  confirmMFAForm: FormGroup;
  constructor(private usersGatewayService: UsersGatewayService,
    private toastr: ToastrService,
    private logger: LoggerService,
    private userService: UserService) {
    super();
    this.useSoftwareMfa();

    const maxlen = 6;
    this.confirmMFAForm = new FormGroup({
      MFAcode: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.minLength(maxlen),
        Validators.maxLength(maxlen)
      ]))
    });
  }

  useSoftwareMfa() {
    this.usersGatewayService.associateSoftwareMFA().subscribe(({SecretCode}) => {
      this.showMfaButton = false;
      this.showQrCode = true;
      const username = this.userService.user.username;
      this.qrCode = `otpauth://totp/${username}?secret=${SecretCode}&digits=6&issuer=${decodeURI('Client Portal')}`;
    });
  }

  verifyMfa() {
    if (this.confirmMFAForm.invalid) return;
    const userCode: any = `${this.confirmMFAForm.value.MFAcode}`;

    this.usersGatewayService.verifySoftwareMFA(userCode)
      .pipe(catchError((response: HttpErrorResponse) => {
        const errorType = response.error.errorType,
        message = response.error.errorMessage;
        switch (errorType) {
          case 'EnableSoftwareTokenMFAException':
            this.confirmMFAForm.controls['MFAcode'].setErrors({
              confirmCodeWrong: true
            });
            break;
          default:
        }

      this.logger.showError(message);

      return throwError(response);
    }))
    .subscribe(() => {
        this.toastr.success('Success');
        this.agree.emit('yes');
    });
  }
}
