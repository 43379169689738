// angular modules
import { ErrorHandler } from '@angular/core';
import { BugsnagService } from './bugsnag.service';
import { UserService } from '@app/shared/services/user.service';

export class BcErrorHandler implements ErrorHandler {

  constructor(private bugsnagService: BugsnagService, private userService: UserService) {
    this.bugsnagService.getConfig(this.userService.userDetails$).subscribe();
   }
  reportHandledError(error) {
    this.bugsnagService.getConfig(this.userService.userDetails$).subscribe(() => this.bugsnagService.client.notify(new Error(error)));
  }

  handleError(error) {
    this.bugsnagService.getConfig(this.userService.userDetails$).subscribe(() => this.bugsnagService.bugSnagHandler.handleError(error));
  }

}
