import gql from 'graphql-tag';

import { Mutation } from '@app/core/common/graphql';
import { Scope } from '../models/scope.model';

const mutationParams = [{name: 'scopes', type: '[Scope]'}];

export class SetAvailableScopesMutation extends Mutation<Array<Scope>> {
  constructor(scopes: Array<Scope>) {
    super('setAvailableScopes', // name
    [],
    mutationParams,
    {scopes},
    true // is a client operation (apollo-link-state)
  );
  }
}
