export const environment = {
  production: false,
  ENV: 'acc',
  BKYC: 'blanco-acc',
  VERSION: 'v4.5.0',

  BASE_URL_BCOS: 'https://api.blanco-acc.cloud/bcos',
  BASE_URL_BGC: 'https://api.blanco-acc.cloud/bgc',
  BASE_URL_BAMS: 'https://api.blanco-acc.cloud/access',
  BASE_URL_BCMD_PARTY_CREDS: 'https://api.blanco-acc.cloud/bcmd-party/creds',

  BUGSNAG_AKEY: '401838e7297d93443b794d8828629c9b',

  redirectTo: {
    onboarding: '.blanco-acc.cloud'
  },

  GraphQlEndpoints: {
    Default: {
      Name: 'default',
      Url: 'https://558jx30pp9.lp.gql.zone/graphql'
    },
    SignUpEndpoint: {
      Name: 'SignUp',
      Url: 'https://6hmhcowfv4.execute-api.eu-central-1.amazonaws.com/dummy/unauthgraphql'
    },
    ProductsEndpoint: {
      Name: 'Products',
      Url: 'https://api.blanco-acc.cloud/bcmd-product/graphql'
    },
    PartiesEndpoint: {
      Name: 'Parties',
      Url: 'https://api.blanco-acc.cloud/bcmd-party/graphql'
    },
    NotificationsEndpoint: {
      Name: 'Notifications',
      Url: 'https://poxy4y3iszc5ritne65jr3ijmy.appsync-api.eu-central-1.amazonaws.com/graphql'
    },
    BsasEndpoint: {
      Name: 'BSAS',
      Url: 'https://api.blanco-acc.cloud/custodians/reports/remoteGraphql'
    }
  },

  LocalCache: {
    Key: 'localCache'
  }
};
