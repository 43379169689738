import { Component, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';

import gql from 'graphql-tag';
import { Subscription, Subject } from 'rxjs';
import { BcModalComponent } from '@app/core/widgets/bc.modal/bc.modal.component';
import { UserService } from '@app/shared/services/user.service';
import { environment } from '@env/environment';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { BcMessagesManager } from '@app/core/widgets/bc.catch-message/bc.messages-manager.service';


const START_KYC = gql`
  mutation activityResolver(
    $id: String!
    $tenantId: String
    $partyId: String!
    $productId: String!
  ) {
    activityResolver(ActivityInput: {
      id: $id,
      tenantId: $tenantId,
      partyId: $partyId,
      productId: $productId,
      action: "START"
    }) {
      response
    }
  }
`;

const EXTEND_KYC = gql`
  mutation activityResolver(
    $id: String!
    $tenantId: String
    $partyId: String!
    $productId: String!
    $explanation: String!
  ) {
    activityResolver(ActivityInput: {
      id: $id,
      tenantId: $tenantId,
      partyId: $partyId,
      productId: $productId,
      explanation: $explanation,
      action: "EXTEND"
    }) {
      response
    }
  }
`;

const GET_KYC_ACTIVITIES = gql`
  query kycActivitiesGet($productId: String!) {
    kycActivitiesGet(productId: $productId){
      id
      status
      createdAt
      surveyId
      trigger
      updatedByUserTypeText
    }
  }
`;

const GET_KYC_HISTORY = gql`
  query kycActivityHistoriesGet($productId: String!) {
    kycActivityHistoriesGet(productId: $productId) {
      outcome
      updatedAt
      surveyId
      id
    }
  }
`;

@Component({
  selector: 'bc-start-kyc-modal.bc-new-modal',
  templateUrl: './start-kyc-modal.component.html',
  styleUrls: [
    './start-kyc-modal.component.scss'
  ]
})
export class StartKycModalComponent extends BcModalComponent implements OnDestroy {
  kycDetails: any;

  productId: string;
  partyId: string;

  form: FormGroup;

  subscription: Subscription;
  unsubscribe$ = new Subject();

  constructor(private userService: UserService,
    private apollo: Apollo,
    private toastr: ToastrService,
    public bcMessagesManager: BcMessagesManager) {
    super();

    this.initForm();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initForm() {
    const actionField: FormControl = new FormControl(null, Validators.required);

    this.form = new FormGroup({
      fillKYC: actionField,
      explanation: new FormControl({ value: null, disabled: true })
    });

    this.subscription = actionField.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value: any) => {
        this.updateValidation(value);
      });
  }

  submit() {
    const formValues = this.form.value;

    if (formValues.fillKYC) {
      this.startKYC();
    } else this.extendKYC(formValues.explanation);
  }

  startKYC() {
    this.apollo
      .use(environment.GraphQlEndpoints.ProductsEndpoint.Name)
      .mutate({
        mutation: START_KYC,
        variables: {
          id: this.kycDetails.id,
          partyId: this.partyId,
          tenantId: this.userService.user.tenantId,
          productId: this.productId
        },
        refetchQueries: [{
          query: GET_KYC_ACTIVITIES,
          variables: {
            productId: this.productId
          }
        }]
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((kyc: any) =>
        this.close.emit( JSON.parse(kyc.data.activityResolver.response))
      , (error) => {
        this.toastr.error(
        this.bcMessagesManager.translations['START_KYC'],
        this.bcMessagesManager.translations['START_KYC_ERROR']);

        this.close.emit(this.kycDetails);
      });
  }

  extendKYC(explanation: string) {
    this.apollo
      .use(environment.GraphQlEndpoints.ProductsEndpoint.Name)
      .mutate({
        mutation: EXTEND_KYC,
        variables: {
          id: this.kycDetails.id,
          tenantId: this.userService.user.tenantId,
          productId: this.productId,
          partyId: this.partyId,
          explanation: explanation
        },
        refetchQueries: [{
          query: GET_KYC_HISTORY,
          variables: {
            productId: this.productId
          }
        }, {
          query: GET_KYC_ACTIVITIES,
          variables: {
            productId: this.productId
          }
        }
      ]
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(x => this.close.emit({refreshNotification: true}),
        (error) => {
          this.close.emit();
          this.toastr.error(
          this.bcMessagesManager.translations['SKIP_KYC'],
          this.bcMessagesManager.translations['SKIP_KYC_ERROR']);
      });
  }

  private updateValidation(value: boolean) {
    if (value) {
      this.form.get('explanation').clearValidators();
      this.form.get('explanation').disable();
    } else {
      this.form.get('explanation').setValidators([Validators.required]);
      this.form.get('explanation').enable();
    }

    this.form.get('explanation').updateValueAndValidity();
  }
}
