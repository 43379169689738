// 3rd party libs
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
// environment
import { environment } from '@env/environment';
import { TokenHelper } from '@blancoservices/bc-auth-web';

@Injectable()
export class ApolloService {
  cache: InMemoryCache;

  constructor(
    private apollo: Apollo,
    private httpLink: HttpLink
  ) {
    this.cache = new InMemoryCache();
  }

  configureEndpoints(): void {
    const endpointsConfig = Object.keys(environment.GraphQlEndpoints).map(
      k => environment.GraphQlEndpoints[k]
    );
    endpointsConfig.forEach(config => {
      const urlToApi = this.httpLink.create({ uri: config.Url });
      this.createClient(
        setContext((_, { headers }) => ({
          headers:
            config.Name === environment.GraphQlEndpoints.SignUpEndpoint.Name
              ? headers || new HttpHeaders()
              : (headers || new HttpHeaders()).append(
                  'authorization',
                  TokenHelper.getAccessToken()
                )
        })).concat(urlToApi),
        this.cache,
        config.Name
      );
    });
  }

  clearDataAfterUserLogout() {
    this.apollo.getClient().resetStore();
  }

  private createClient(link, cache, name): void {
    this.apollo.create(
      {
        link,
        cache
      },
      name
    );
  }
}
