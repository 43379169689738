<div class="modal-dialog modal-dialog_large">
  <div class="modal-content">
    <div class="modal-header">
      <button (click)="onClose($event)" type="button" class="close u-unstyled-btn">
        <svg class="svg-icon" width="21" height="21">
          <use xlink:href="#remove-icon"></use>
        </svg>
      </button>
      <h3 class="modal-title" i18n="@@registerAuthApp">Register Authentication application</h3>
    </div>

    <form [formGroup]="confirmMFAForm" (ngSubmit)="verifyMfa()" novalidate>
      <div class="modal-body" *ngIf="showQrCode; else: loadingBlock">
        
          <div class="centered-qr-code">
            <qrcode *ngIf="showQrCode" [qrdata]="qrCode" [size]="256" [level]="'M'"></qrcode>
          </div>

          <div class="field__item">
            <label class="field__label text-left" i18n="@@downloadAuthApp">Download an Authenticator app and scan the QR code. You will receive a number in your phone. Please  enter the number below</label>
          </div>
        

            <div class="form-group auth__form-group">
              <label for="MFAcode" i18n="@@enterAuthCode">Enter authentication code</label>
              <input type="text"
                    class="form-control"
                    name="MFAcode"
                    id="MFAcode"
                    formControlName="MFAcode">
              <bc-messages [element]="confirmMFAForm.get('MFAcode')"></bc-messages>
            </div>
      </div>
      <hr>
      <div class="modal-footer justify-buttons">
        <button class="btn bc-btn bc-btn_custom bc-btn_block" i18n="@@confirm" [disabled]="confirmMFAForm.invalid" type="submit">Confirm</button>
      </div>
    </form> 
  
  </div>
</div>
<ng-template #loadingBlock> 
    <div class="modal-backdrop fade show data-load-backdrop">
        <span>
          <i class="fa fa-circle-o-notch fa-spin"></i>
        </span>
      </div>
 </ng-template>
