import { GraphQLOperation } from './graphql-operation';
import { GraphQLOperationType } from './graphql-operation-type';

/**
 * A graphql operation that can be used to create multiple operations
 * combined into a batch ready to be sent in a single network call.
 */
export abstract class CombinableOperation<T> extends GraphQLOperation<T> {
  private readonly combinedOperationCalls: Array<string>;
  constructor(name: string, type: GraphQLOperationType, ...operations: Array<GraphQLOperation<any>>) {
    super(name, type);
    this.combinedOperationCalls = [];
    operations.forEach(operation => this.combine(operation));
  }

  /**
   * Combine another operation with this operation.
   * @param operation the graphql operation to be combined.
   */
  combine<U>(operation: GraphQLOperation<U>): CombinableOperation<T> {
    if (this.type !== operation.type) {
      throw new Error(`Combining a ${this.type} and a ${operation.type} is not allowed`);
    }
    this.mergeParams(operation.params);
    this.combineOperationCall(operation.strippedOperationCall);

    return this;
  }

  get strippedOperationCall(): string {
    return this.combinedOperationCalls.join(' ');
  }

  private mergeParams(newParams: Array<{ name: string, type: string }>): void {
    for (const newParam of newParams) {
      if (!this.operationParams.find(param => param.name === newParam.name)) {
        this.operationParams.push(newParam);
      }
    }
  }

  private combineOperationCall(operationCall: string): void {
    this.combinedOperationCalls.push(operationCall);
  }
}
