import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { BcPhoneInputComponent } from './bc.phone-input.component';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    DxTextBoxModule,
    DxValidatorModule
  ],
  declarations: [
    BcPhoneInputComponent
  ],
  exports: [
    BcPhoneInputComponent
  ]
})
export class BcPhoneInputModule {  }
