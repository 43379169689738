import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedAuthModule } from './shared.auth.module';
import { LoginModule } from './login/login.module';
import { ConfirmCodeModule } from './confirm/confirm.module';
import { ContinueModule } from './continue/continue.module';

import { QRCodeModule } from 'angularx-qrcode';
import { authRoutes } from './auth.routes';
import { SoftwareMfaRegisterModalComponent } from './software-mfa-register/software-mfa-register-modal.component';

@NgModule({
  imports: [
    SharedAuthModule,
    QRCodeModule,
    LoginModule,
    ContinueModule,
    ConfirmCodeModule,
    RouterModule.forChild(authRoutes)
  ],
  declarations: [SoftwareMfaRegisterModalComponent],
  entryComponents: [SoftwareMfaRegisterModalComponent],
  exports: [LoginModule, ConfirmCodeModule]
})
export class AuthModule {}
