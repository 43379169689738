<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <button (click)="onClose($event)" type="button" class="close pull-right u-unstyled-btn icon-link h4-header h4-header_charcoal">
        <svg class="icon-link__icon svg-icon" width="14" height="14">
          <use bcInlineHref="#remove-icon"></use>
        </svg>
        <ng-container i18n="@@close">Close</ng-container>
      </button>
      <h3 *ngIf="title" class="modal-title">{{ title }}</h3>
      <h3 *ngIf="!title" i18n="modal dialog" class="modal-title">Are you sure?</h3>
    </div>
    <div class="modal-body">
      <p *ngFor="let question of questions">{{ question }}</p>
    </div>
    <div class="modal-footer">
      <button (click)="onYes($event)" class="btn bc-btn bc-btn_custom" i18n="@@yes">Yes</button>
      <button (click)="onNo($event)" class="btn bc-btn bc-btn_default" i18n="@@no">No</button>
    </div>
  </div>
</div>
