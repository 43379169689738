<bc-catch-messages [manager]="bcLocalesManager">
  <bc-message key="en-US" i18n="@@enUSLanguage">English</bc-message>
  <bc-message key="nl-NL" i18n="@@nlNLLanguage">Dutch</bc-message>
  <bc-message key="fr-FR" i18n="@@frFRLanguage">French</bc-message>
</bc-catch-messages>

<bc-catch-messages [manager]="leiStatusesManager">
  <bc-message key="DUPLICATE" i18n="@@duplicate">Duplicate</bc-message>
  <bc-message key="ANNULLED" i18n="@@annulled">Annulled</bc-message>
  <bc-message key="ISSUED" i18n="@@issued">Issued</bc-message>
  <bc-message key="LAPSED" i18n="@@lapsed">Lapsed</bc-message>
  <bc-message key="MERGED" i18n="@@merged">Merged</bc-message>
  <bc-message key="PENDING_ARCHIVAL" i18n="@@pendingarchival">Pending archival</bc-message>
  <bc-message key="PENDING_TRANSFER" i18n="@@pendingtrasfer">Pending transfer</bc-message>
  <bc-message key="RETIRED" i18n="@@retired">Retired</bc-message>
</bc-catch-messages>
