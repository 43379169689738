import {
  ValidatorFn,
  AbstractControl,
  Validators,
  FormGroup,
  FormControl
} from '@angular/forms';
// const IBAN = require('iban');

// tslint:disable:max-line-length
export const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const REQ_SPECIAL_PATTERN = /[\^\$\*\.\[\]\{\}\(\)\?\-\“\!\@\#\%\&\/\,\>\<\‘\:\;\|\_\~\`]/;
export const REQ_UPPER_CASE_PATTERN = /[A-Z]/;
export const REQ_LOWER_CASE_PATTERN = /[a-z]/;
export const REQ_NUMBER_PATTERN = /\d/;
export const USERNAME_LOGIN_PATTERN = /^[^ ]+$/;
export const USERNAME_PATTERN = /^[\\da-z_.!@#$%^&*()_+-=]+$/;
// tslint:enable:max-line-length


export class CustomValidators {
  static minNumber(min: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;

      return value >= min ? null : { minNumber: { min } };
    };
  }

  static maxNumber(max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;

      return value <= max ? null : { maxNumber: { max } };
    };
  }

  static reqNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;

      return REQ_NUMBER_PATTERN.test(value) ? null : { reqNumber: { name } };
    };
  }

  static reqSpecial(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;

      return REQ_SPECIAL_PATTERN.test(value) ? null : { reqSpecial: { name } };
    };
  }

  static reqUpperCase(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;

      return REQ_UPPER_CASE_PATTERN.test(value) ? null : { reqUpperCase: { name } };
    };
  }

  static reqLowerCase(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;

      return REQ_LOWER_CASE_PATTERN.test(value) ? null : { reqLowerCase: { name } };
    };
  }

  static wrongPassword(value: boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return value ? null : { confirmPassword: { name } };
    };
  }

  static reqEmail(isRequired: boolean = true): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      const length = value ? value.length : 0;

      if (!isRequired && length === 0) {
        return null;
      }

      return EMAIL_PATTERN.test(value) ? null : { reqEmail: { name } };
    };
  }

  static reqUrl(isRequired: boolean = true): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      const length = value ? value.length : 0;

      if (!isRequired && length === 0) {
        return null;
      }

      const urlRegex = /^(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,8}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

      return urlRegex.test(value) ? null : { reqUrl: { name } };
    };
  }

  static validateMobilePhone(isRequired: boolean = true): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      const length = value ? value.length : 0;

      if (!isRequired && length === 0) return null;

      // tslint:disable-next-line:no-magic-numbers
      if ((value === null || value.length < 6) && isRequired) {
        return { reqMobilePhone: { name } };
      }

      const phoneTypes = (<any> window).phonenumbers.PhoneNumberType;
      const phoneUtil = (<any> (
        window
      )).phonenumbers.PhoneNumberUtil.getInstance();

      let num: any;
      try {
        num = phoneUtil.parseAndKeepRawInput(value);
      } catch (e) {
        return { reqMobilePhone: { name } };
      }

      const numberType = phoneUtil.getNumberType(num);

      const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

      return numberType === phoneTypes.MOBILE && phoneRegex.test(value)
        ? null
        : { reqMobilePhone: { name } };
    };
  }

  static validateLandOrMobilePhone(isRequired: boolean = true): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      const length = value ? value.length : 0;

      if (!isRequired && length === 0) return null;

      // tslint:disable-next-line:no-magic-numbers
      if (value === null || value.length < 6) {
        return { reqMobilePhone: { name } };
      }

      const phoneTypes = (<any> window).phonenumbers.PhoneNumberType;
      const phoneUtil = (<any> (
        window
      )).phonenumbers.PhoneNumberUtil.getInstance();

      let num: any;
      try {
        num = phoneUtil.parseAndKeepRawInput(value);
      } catch (e) {
        return { reqLandOrMobilePhone: { name } };
      }

      const numberType = phoneUtil.getNumberType(num);

      const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

      return (numberType === phoneTypes.FIXED_LINE ||
        numberType === phoneTypes.FIXED_LINE_OR_MOBILE ||
        numberType === phoneTypes.MOBILE ||
        numberType === phoneTypes.VOIP) &&
        phoneRegex.test(value)
        ? null
        : { reqLandOrMobilePhone: { name } };
    };
  }

  static optionalDate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

      return (value && dateRegex.test(value)) || !value
        ? null
        : { optionalDate: { name } };
    };
  }

  static validateAlphanumeric(
    controlName?: string,
    isRequired: boolean = true
  ): ValidatorFn {
    return (control: FormControl): { [key: string]: any } => {
      const value = control.value;
      const length = value ? value.length : 0;

      if (!isRequired && length === 0) return null;

      const alphanumericRegex = /^[a-z0-9]+$/i;

      let errorObject: any = { alphanumeric: { name } };

      if (controlName === 'TIN_NP') {
        errorObject = { alphanumericTINNP: { name } };
      }
      if (controlName === 'TIN_LE') {
        errorObject = { alphanumericTINLE: { name } };
      }
      if (controlName === 'BAN') errorObject = { alphanumericBAN: { name } };

      return alphanumericRegex.test(value) ? null : errorObject;
    };
  }

  static requiredOptional(checkboxState: boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;

      return value && checkboxState ? null : { required: { name } };
    };
  }

  // static validateIBAN(isRequired: boolean = true): ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: any } => {
  //     const value = control.value;
  //     const length = value ? value.length : 0;

  //     if (!isRequired && length === 0) return null;

  //     return IBAN.isValid(value) ? null : { invalidIBAN: { name } };
  //   };
  // }
}
