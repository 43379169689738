import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'bc-messages',
  templateUrl: 'bc.messages.component.html'
})
export class BcMessagesComponent {
  @Input() element: FormControl;

  constructor() { }
}
