import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { UserService, User } from '@app/shared/services/user.service';
import { switchMap } from 'rxjs/operators';
import { SettingsService } from '@app/shared/services/integrations-settings.service';
import { of } from 'rxjs';

@Component({
  selector: 'bc-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit {
  locale: string;
  locales = [
    { value: 'en-US', name: 'English'},
    { value: 'nl-NL', name: 'Nederlands'},
    { value: 'fr-FR', name: 'Français'},
  ];

  constructor(@Inject(LOCALE_ID) private localeId: string,
              private userService: UserService,
              private settingsService: SettingsService) {
    this.locale = localeId;
  }

  ngOnInit() {
    this.userService.userDetails$
    .pipe(
      switchMap((user: User) => user && user.tenantId ? this.settingsService.getLocalisationSettings(user.tenantId) : of({}))
    )
    .subscribe(({allowedLocales}: any) => {
      if (!allowedLocales) return;
      this.locales = this.locales.filter((locale: any) => allowedLocales.includes(locale.value));
    });
  }
  switchLocale(locale: string) {
    // tslint:disable:no-magic-numbers
    const monthAfterToday = new Date(Date.now() + 2592000000);
    const hostDomain = document.location.host.split('.').slice(-2).join('.');
    // tslint:enable:no-magic-numbers

    document.cookie = `locale=${locale}; expires=${monthAfterToday.toUTCString()}; domain=${hostDomain}; Path=/;`;

    location.reload(true);
  }
}
