import { GraphQLOperationType } from './graphql-operation-type';
import { CombinableOperation } from './combinable-operation';
import { Mutation } from './mutation';
import { DocumentNode } from 'graphql';

/**
 * A query that can be combined with other queries into a batch to be sent
 * in a single network call.
 */
export class CombinableMutation<T> extends CombinableOperation<T> {
  constructor(name: string, ...mutations: Array<Mutation<any>>) {
    super(name, GraphQLOperationType.MUTATION, ...mutations);
  }

  /**
   * get the GraphQL mutation to be sent to the endpoint.
   */
  get mutation(): DocumentNode {
    return this.compiledOperationCall;
  }
}
