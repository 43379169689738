import { setAvailableScopesResolver } from './set-available-scopes.resolver';
import { setCurrentScopeResolver } from './set-current-scope.resolver';

const configuredResolvers = [
  setAvailableScopesResolver,
  setCurrentScopeResolver
];

export const resolvers = {
  resolvers: {
    Mutation: Object.assign.apply(null, configuredResolvers.map(resolver => resolver.Mutation)),
  },
  defaults: Object.assign.apply(null, configuredResolvers.map(resolver => resolver.defaults)),
};
