import { NgModule } from '@angular/core';

import { ShowPasswordDirective } from './bc.show-password.directive';

@NgModule({
  imports: [],
  declarations: [ShowPasswordDirective],
  providers: [],
  exports: [ShowPasswordDirective]
})
export class ShowPasswordModule {}
