import gql from 'graphql-tag';

import { Query } from '@app/core/common/graphql';
import { Scope } from '../models/scope.model';

export class CurrentScopeQuery extends Query<Scope> {
  constructor() {
    super('currentScope',
      ['id', 'Name'], // properties to fetch
      [], // params names/types
      {}, // variables
      true, // is client query
    );
  }
}
