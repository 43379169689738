// 3rd party libs
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserLoginService } from '@app/shared/services/UserLoginService';
import { TokenHelper } from '@blancoservices/bc-auth-web';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    router: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (TokenHelper.isAccesTokenValid()) return true;
    this.router.navigate(['login'], { queryParams: router.queryParams });

    return false;
  }
}
