import { Component, OnInit, OnDestroy } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';

import gql from 'graphql-tag';

import { Subscription, Subject, Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { BcModalComponent } from '@app/core/widgets/bc.modal/bc.modal.component';
import { UserService } from '@app/shared/services/user.service';
import { map, takeUntil } from 'rxjs/operators';

const GET_KYC_ACTIVITIES_LOGS = gql`
  query kycActivityLogGet(
    $id: String!
  ) {
    kycActivityLogGet(
      id: $id
    ) {
      activity
      user
      updatedAt
      id
    }
  }
`;


@Component({
  selector: 'bc-view-kyc-logs-modal.bc-new-modal',
  templateUrl: './view-kyc-logs-modal.component.html',
  styleUrls: [
    './view-kyc-logs-modal.component.scss'
  ]
})
export class ViewKycLogsModalComponent extends BcModalComponent implements OnInit, OnDestroy {
  productId: string;
  kycDetails: any;

  logsSubscription$: Subject<Boolean>;
  logs$: Observable<any>;

  constructor(private apollo: Apollo, private userService: UserService) {
    super();
    this.logsSubscription$ = new Subject<Boolean>();
  }

  ngOnInit() {
    this.logs$ = this.apollo
      .use(environment.GraphQlEndpoints.ProductsEndpoint.Name)
      .watchQuery({
        query: GET_KYC_ACTIVITIES_LOGS,
        fetchPolicy: 'network-only',
        variables: {
          id: this.kycDetails.id
        }
      })
      .valueChanges.pipe(takeUntil(this.logsSubscription$), map(({ data }) => data['kycActivityLogGet']));
  }

  ngOnDestroy() {
    this.logsSubscription$.next(true);
    this.logsSubscription$.complete();
  }
}
