export const setAvailableScopesResolver = {
  Mutation: {
    setAvailableScopes: (_, { scopes }, { cache }) => {
      const currentScope = {__typename: 'Scope', ...scopes[0]};
      const availableScopes = scopes.length > 1 ? scopes.slice(1) : [];

      const newScopes = availableScopes.map(s => ({ ...s, __typename: 'Scope' }));
      const data = {
        scopes: newScopes,
        currentScope
      };

      cache.writeData({ data });

      return newScopes;
    },
  },
  defaults: {
    scopes: [],
  },
};
