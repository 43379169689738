import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BcMessagesModule } from '@app/core/widgets/bc.messages/bc.messages.module';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BcMessagesModule,

    DxFormModule,
    DxButtonModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxLoadPanelModule
  ],
  exports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BcMessagesModule,

    DxFormModule,
    DxButtonModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxLoadPanelModule
  ]
})
export class SharedAuthModule {}
