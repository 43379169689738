import { NgModule } from '@angular/core';
import { SharedAuthModule } from '../shared.auth.module';

import { ShowPasswordModule } from '../../../widgets/bc.show-password/bc.show-password.module';
import { BcCatchMessagesModule } from '@app/core/widgets/bc.catch-message/bc.catch-messages.module';
import { LanguageSwitcherModule } from '@shared/components/language-switcher/language-switcher.module';
import { PipeModule } from '@app/shared/pipes/pipe.module';

import { LoginComponent } from './login.component';

import { BcMessagesManager } from '@app/core/widgets/bc.catch-message/bc.messages-manager.service';


@NgModule({
  imports: [
    SharedAuthModule,
    ShowPasswordModule,
    PipeModule,
    LanguageSwitcherModule,
    BcCatchMessagesModule
  ],
  declarations: [LoginComponent],
  providers: [BcMessagesManager]
})
export class LoginModule {}
