import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Helpers } from '@app/shared/services/helpers.service';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
// tslint:disable-next-line: import-spacing
import { UsersGatewayService } from '@blancoservices/bc-auth-web';

export interface Name {
  full: string;
  family: string;
  given: string;
  middle?: string;
}

export interface User {
  username: string;
  email: string;
  emailVerified: boolean;
  name: Name;
  phoneNumber: string;
  phoneNumberVerified: boolean;
  partyId: string;
  tenantId: string;
  roles: string;
  sub: string;
}

@Injectable()
export class UserService {
  user: User;
  userDetails$: BehaviorSubject<User>;

  constructor(private usersGateway: UsersGatewayService
  ) {
    this.userDetails$ = new BehaviorSubject(this.user);
  }

  updateTenantId(tenantId: string) {
    this.user = {...this.user, tenantId };
    this.userDetails$.next(this.user);
  }

  updateUser(): Promise<User> {
    return this.getParameters().then((result: any) => {
      this.user = {
        username: result.username || result.email,
        email: result.email,
        emailVerified: result.email_verified === true,
        name: {
          full: `${result.given_name} ${
            result.middle_name ? result.middle_name : ' '
          } ${result.family_name}`,
          given: result.given_name,
          family: result.family_name,
          middle: result.middle_name || null
        },
        phoneNumber: result.phone_number,
        phoneNumberVerified: result.phone_number_verified === true,
        partyId: result.preferred_username,
        roles: result.profile,
        sub: result.sub,
        tenantId: ''
      };

      return this.user;
    });
  }

  isItMe(user: any): boolean {
    return user.username === this.user.username;
  }

  clear() {
    this.user = {
      username: '',
      email: '',
      emailVerified: false,
      name: {
        full: '',
        given: '',
        family: '',
        middle: ''
      },
      phoneNumber: '',
      phoneNumberVerified: false,
      partyId: '',
      roles: '',
      sub: '',
      tenantId: ''
    };
  }

  private getParameters() {
    return new Promise((resolve, reject) => {
      this.usersGateway.getCurrentUser()
        .pipe(catchError((response: HttpErrorResponse) => {
          reject('cognito user not found');

          return throwError(response);
        }))
        .subscribe((user: any) => {

        const cognitoUser: any = user;

        if (cognitoUser) {
          resolve({...cognitoUser, roles: [cognitoUser.profile]});
        } else {
          reject('cognito user not found');
        }
      });
    });
  }
}
