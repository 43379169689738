// 3rd party libs
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
// services

@Injectable()
export class AfterSignUpFormGuard implements CanActivate {

  constructor(
    private router: Router
  ) {}

  canActivate(router: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // if (this.authService.isFirstLogin) {
    //   this.router.navigate(['/after-sign-up-form']);

    //   return false;
    // }

    return true;
  }

}
