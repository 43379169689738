<bc-catch-messages [manager]="bcMessagesManager">
  <bc-message key="UNDEFINED_ERROR" i18n>Something went wrong. Please try login later.</bc-message>

  <bc-message key="INVALID_FORM_ERROR" i18n>Please complete the fields with valid input.</bc-message>
  <bc-message key="DISABLED_USER_ERROR" i18n>User is disabled.</bc-message>
  <bc-message key="INCORRECT_CRED_ERROR" i18n>Incorrect username or password.</bc-message>
  <bc-message key="CONFIRMATION_ERROR" i18n>User is not confirmed.</bc-message>
  <bc-message key="PROFILE_MISSING_ERROR" i18n>Invalid attributes given, profile is missing. Please contact us.</bc-message>
</bc-catch-messages>

<section class="signup signup__indent">
  <h1 class="signup__title" i18n>Welcome! Please log in</h1>
  <div class="confirmation__form-wrap">
    <dx-form id="form" [formData]="loginData" labelLocation="top">
      <dxi-item dataField="userName" [editorOptions]="{ disabled: tempPass }">
        <dxo-label text="Username" i18n-text="@@username"></dxo-label>
        <dxi-validation-rule type="pattern" [pattern]="usernamePattern" 
                            i18n-mesage="@@errorpattern" message="Field contains invalid character(s)"></dxi-validation-rule>
        <dxi-validation-rule type="required"
                             i18n-message="@@usernameRequired" message="Username is required"></dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="password"
                [editorOptions]="{ mode: 'password', disabled: tempPass }">
        <dxo-label text="Password" i18n-text="@@password"></dxo-label>
        <dxi-validation-rule type="required"
                             i18n-mesage message="New password is required"></dxi-validation-rule>
        <dxi-validation-rule type="stringLength" [min]="8"
                             i18n-message message="Password must have at least 8 symbols"></dxi-validation-rule>
        <dxi-validation-rule type="stringLength" [max]="99"
                             i18n-message message="Password must have less than 99 symbols"></dxi-validation-rule>
        <dxi-validation-rule type="pattern" [pattern]="numberPattern"
                             i18n-message message="You have to include at least one Number"></dxi-validation-rule>
        <dxi-validation-rule type="pattern" [pattern]="lowerCasePattern"
                             i18n-message message="You have to include at least one Lower Case letter"></dxi-validation-rule>
        <dxi-validation-rule type="pattern" [pattern]="upperCasePattern"
                             i18n-message message="You have to include at least one Upper Case letter"></dxi-validation-rule>
        <dxi-validation-rule type="pattern" [pattern]="specialsPattern"
                             i18n-message message="You have to include at least one special character"></dxi-validation-rule>
      </dxi-item>

      <dxi-item [visible]="tempPass">
        <div class="warning">
          <div class="warning-sign">
            <svg class="svg-icon" width="26" height="23">
              <use x="0" xlink:href="#icon-warning" y="0"></use>
            </svg>
          </div>
          <div class="message-body">
            <strong class="title" i18n="@@changePassword">Change password</strong>
            <p class="message" i18n>
              Please, change your password below.
            </p>
          </div>
          <div class="close">
            <i class="fa fa-times"></i>
          </div>
        </div>
      </dxi-item>

      <dxi-item dataField="newPassword" [visible]="tempPass"
                [editorOptions]="{ mode: 'password' }">
        <dxo-label text="New password" i18n-text="@@newPassword"></dxo-label>
        <dxi-validation-rule type="required"
                             i18n-mesage message="New password is required"></dxi-validation-rule>
        <dxi-validation-rule type="stringLength" [min]="8"
                             i18n-message message="Password must have at least 8 symbols"></dxi-validation-rule>
        <dxi-validation-rule type="stringLength" [max]="99"
                             i18n-message message="Password must have less than 99 symbols"></dxi-validation-rule>
        <dxi-validation-rule type="pattern" [pattern]="numberPattern"
                             i18n-message message="You have to include at least one Number"></dxi-validation-rule>
        <dxi-validation-rule type="pattern" [pattern]="lowerCasePattern"
                             i18n-message message="You have to include at least one Lower Case letter"></dxi-validation-rule>
        <dxi-validation-rule type="pattern" [pattern]="upperCasePattern"
                             i18n-message message="You have to include at least one Upper Case letter"></dxi-validation-rule>
        <dxi-validation-rule type="pattern" [pattern]="specialsPattern"
                             i18n-message message="You have to include at least one special character"></dxi-validation-rule>
      </dxi-item>

      <dxi-item [visible]="tempPass">
        <p class="auth__text" i18n>
          The new password should include at least one Number, One special character, one Upper case letter and one Lower case letter.
        </p>
      </dxi-item>

      <dxi-item dataField="confirmNewPassword" [visible]="tempPass"
                [editorOptions]="{ mode: 'password' }">
        <dxo-label text="Confirm new password" i18n-text="@@confirmNewPassword"></dxo-label>
        <dxi-validation-rule type="required"
                             i18n-mesage message="Please confirm your password"></dxi-validation-rule>
        <dxi-validation-rule type="compare" [comparisonTarget]="passwordComparison"
                             i18n-mesage message="New Password and Confirm Password do not match"></dxi-validation-rule>
      </dxi-item>

      <dxi-item>
        <div class="field__item checkbox__field">
          <input class="checkbox__field"
                 type="checkbox"
                 id="remember-me"
                 name='remember-me'
                 [ngModel]="rememberMe">

          <label class="checkbox__label checkbox__label_smaller"
                 for="remember-me" i18n>
            Remember me
          </label>

          <span class="checkbox__check"></span>
        </div>
      </dxi-item>
    </dx-form>

    <div class="dx-btn dx-btn__success bc-btn_block">
      <dx-button (onClick)="submit()"
                 i18n="@@login" text="Login"></dx-button>
    </div>

    <div class="activities">
      <button type="button" (click)="gotoForgotPassword()" i18n
              class="u-unstyled-btn forgot-password text-link text-link_custom">Forgot your password?</button>
      <bc-language-switcher></bc-language-switcher>
    </div>
  </div>
</section>

<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingVisible" [closeOnOutsideClick]="false"></dx-load-panel>

