import { ApolloBase } from 'apollo-angular/Apollo';
import { Apollo } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

import { Query, Mutation, IQuery } from '@app/core/common/graphql';

export abstract class ServiceBase {
  constructor(private apollo: Apollo, protected toast: ToastrService) {}
  abstract get clientName(): string;

  /**
   * @ deprecated Use the `query`, `watchQuery` and `mutate` wrapper methods of this class instead of accessing
   * the apollo client directly
   */
  protected get client(): ApolloBase<any> {
    return this.apollo.use(this.clientName);
  }

  protected get forceUpdateProp(): { fetchPolicy: string } {
    return { fetchPolicy: 'network-only' };
  }

  query<T>(gqlQuery: Query<T>, options?: any): Observable<T> {
    return this.client
      .query({
        query: gqlQuery.query,
        ...options,
        variables: gqlQuery.variables
      })
      .pipe(
        map(({ data }) => gqlQuery.extractResult(data)),
        catchError(error => {
          console.error(error);
          this.toast.error(error.message, 'Error');

          return of({} as T);
        })
      );
  }

  querySingle<T>(gqlQuery: Query<Array<T>>, options?: any): Observable<T> {
    return this.query<Array<T>>(gqlQuery, options).pipe(map((result: Array<T>) => result[0]));
  }

  watchQuery<T>(gqlQuery: IQuery<T>, options?: any): Observable<T> {
    return this.client
      .watchQuery({
        query: gqlQuery.query,
        ...options,
        variables: gqlQuery.variables
      })
      .valueChanges.pipe(
        map(({ data }) => gqlQuery.extractResult(data)),
        catchError(error => {
          console.error(error);
          this.toast.error(error.message, 'Error');

          return of({} as T);
        })
      );
  }

  watchQuerySingle<T>(gqlQuery: Query<Array<T>>, options?: any): Observable<T> {
    return this.watchQuery<Array<T>>(gqlQuery, options).pipe(map((result: Array<T>) => result[0]));
  }

  mutate<T>(gqlMutation: Mutation<T>, options?: any): Observable<T> {
    return this.client
      .mutate({
        mutation: gqlMutation.mutation,
        variables: gqlMutation.variables,
        ...options
      })
      .pipe(
        map(({ data }) => gqlMutation.extractResult(data)),
        catchError(error => {
          console.error(error);
          throw new Error(error.graphQLErrors.map(x => x.message));
          // return of({ } as T);
        })
      );
  }
}
