<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <button (click)="onClose($event)" type="button"
              class="close u-unstyled-btn">
        <svg class="svg-icon" width="21" height="21">
          <use bcInlineHref="#remove-icon"></use>
        </svg>
      </button>
      <h3 class="modal-title" i18n>View status details</h3>
    </div>
    <div class="modal-body">
      <h4 class="h3-header h3-header_charcoal" i18n>KYC activities list</h4>

      <dx-data-grid id="logs"
                    [dataSource]="logs$ | async"
                    [columnResizingMode]="'widget'"
                    [columnAutoWidth]="true"
                    [allowColumnResizing]="true"
                    [allowColumnReordering]="true"
                    [showRowLines]="true">

        <dxi-column dataField="updatedAt" dataType="date" sortOrder="desc" [format]="{ type: 'MMMM d, y, H:mm:ss' }"
                    caption="Date & Time" i18n-caption="@@dateAndTime"></dxi-column>
        <dxi-column dataField="user" caption="User" i18n-caption="@@user"></dxi-column>

        <dxi-column dataField="activity" cellTemplate="actionsTemplate" width="105"
                    caption="Activity" i18n-caption="@@activity"></dxi-column>

        <div *dxTemplate="let d of 'actionsTemplate'">
          <ng-container [ngSwitch]="d.data.activity">
            <span class="status-label status-label_tiny status-label_started"
                  *ngSwitchCase="'START'" i18n="@@notStarted">Started</span>
            <span class="status-label status-label_tiny status-label_created"
                  *ngSwitchCase="'NEW'" i18n="@@notStarted">Created</span>
            <span class="status-label status-label_tiny status-label_opened"
                  *ngSwitchCase="'FILL'" i18n="@@opened">Opened</span>
            <span class="status-label status-label_tiny status-label_opened"
                  *ngSwitchCase="'OPENED'" i18n="@@opened">Opened</span>
            <span class="status-label status-label_tiny status-label_invite-sent"
                  *ngSwitchCase="'INVITE'" i18n="@@invite">Invite sent</span>
            <span class="status-label status-label_tiny status-label_cancelled"
                  *ngSwitchCase="'CANCEL'" i18n="@@cancelled">Cancelled</span>
            <span class="status-label status-label_tiny status-label_submitted"
                  *ngSwitchCase="'SUBMITTED'" i18n>Submitted</span>
            <span *ngSwitchDefault>{{ d.data.activity }}</span>
          </ng-container>
        </div>
      </dx-data-grid>
    </div>

    <div class="modal-footer justify-buttons">
      <button class="btn bc-btn bc-btn_default bc-btn_block bc-btn_tiny"
              i18n="@@back" type="button"
              (click)="onClose($event)">Back</button>
      <button class="btn bc-btn bc-btn_custom bc-btn_block bc-btn_tiny"
              i18n="@@continue" type="submit"
              (click)="onClose($event)">Continue</button>
    </div>
  </div>
</div>
