import { NgModule } from '@angular/core';
import { SharedAuthModule } from '../shared.auth.module';

import { ConfirmCodeComponent } from './confirm.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BcPhoneInputModule } from '@app/core/widgets/bc.phone-input/bc.phone-input.module';
import { BcCatchMessagesModule } from '@app/core/widgets/bc.catch-message/bc.catch-messages.module';
import { PipeModule } from '@app/shared/pipes/pipe.module';

import { BcMessagesManager } from '@app/core/widgets/bc.catch-message/bc.messages-manager.service';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxValidationSummaryModule } from 'devextreme-angular/ui/validation-summary';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';


@NgModule({
  imports: [
    SharedAuthModule,
    DxButtonModule,
    BcPhoneInputModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    ReactiveFormsModule,
    DxLoadPanelModule,
    PipeModule,
    BcCatchMessagesModule
  ],
  declarations: [ConfirmCodeComponent],
  providers: [BcMessagesManager]
})
export class ConfirmCodeModule {}
