import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { ApolloConfigurationService } from './apollo-configuration.service';
import { ApplicationStateService } from './app-state';
import { UserService } from '@app/shared/services/user.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { BcBugSnagModule } from '../common/bugsnag/bugsnag.module';
import { ToastrModule } from 'ngx-toastr';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    BcBugSnagModule,
    ToastrModule,
    // Apollo
    ApolloModule,
    HttpLinkModule,
    DxDataGridModule
  ],
  providers: [
    ApolloConfigurationService,
    ApplicationStateService,
    UserService,
    LoggerService
  ],
  declarations: [],
  entryComponents: []
})
export class ServicesModule {
  constructor(private apollo: Apollo, private httpLink: HttpLink) {}
}
