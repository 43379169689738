import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { of } from 'rxjs/internal/observable/of';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TokenHelper } from '@blancoservices/bc-auth-web';
import { LoggerService } from '@app/shared/services/logger.service';

const bcmdPartyURL = environment.BASE_URL_BCMD_PARTY_CREDS;

const toBeSignedApis = [
  environment.BASE_URL_BCOS,
  environment.BASE_URL_BAMS,
  environment.GraphQlEndpoints.PartiesEndpoint.Url,
  environment.GraphQlEndpoints.ProductsEndpoint.Url,
  environment.GraphQlEndpoints.NotificationsEndpoint.Url,
  environment.GraphQlEndpoints.BsasEndpoint.Url
];
const openApis = [
  {
    url: bcmdPartyURL,
    method: 'POST'
  },
  {
    url: `${environment.BASE_URL_BCOS}/tenants/settings`,
    method: 'GET'
  },
  {
    url: `${environment.BASE_URL_BCOS}/users`,
    method: 'POST'
  },
  {
    url: `${environment.BASE_URL_BAMS}/users?invite_id`,
    method: 'POST'
  },
  {
    url: `${environment.BASE_URL_BAMS}/users?login`,
    method: 'POST'
  },
  {
    url: `${environment.BASE_URL_BAMS}/users?mfa`,
    method: 'POST'
  },
  {
    url: `${environment.BASE_URL_BAMS}/users?forgot`,
    method: 'POST'
  },
  {
    url: `${environment.BASE_URL_BAMS}/users?getHash`,
    method: 'POST'
  }
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public router: Router,
              public logger: LoggerService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const shouldBeSigned =
      !openApis.some((api) => request.url.startsWith(api.url) && request.method === api.method) &&
      toBeSignedApis.some((url) => request.url.startsWith(url));

    if (shouldBeSigned) {
        const token =  TokenHelper.getAccessToken();

        if (token && TokenHelper.isAccesTokenValid()) {
          request = request.clone({
            setHeaders: {
              Authorization: token
            }
          });

        return next.handle(request).pipe(catchError(this.catcherr.bind(this)));
      } else {
        if (token) {
          TokenHelper.clearLocalStorage();
          this.logger.showError(new Error('Session expired.'));
        }

        return throwError(new HttpErrorResponse({status: 401}))
                .pipe(catchError(this.catcherr.bind(this)));
      }
    }

    return next.handle(request).pipe(catchError(this.catcherr.bind(this)));
  }
  private catcherr(err: HttpErrorResponse) {
    if (err instanceof HttpErrorResponse) {
      // tslint:disable-next-line:no-magic-numbers
      if (err.status === 401) {
        TokenHelper.clearLocalStorage();
        this.router.navigate(['/login']);
      }

      return throwError(err);
    }
  }
}
