
import { GraphQLOperationType } from './graphql-operation-type';
import { CombinableOperation } from './combinable-operation';
import { Query } from './query';
import { DocumentNode } from 'graphql';

/**
 * A query that can be combined with other queries into a batch to be sent
 * in a single network call.
 */
export class CombinableQuery<T> extends CombinableOperation<T> {
  constructor(name: string, ...queries: Array<Query<any>>) {
    super(name, GraphQLOperationType.QUERY, ...queries);
  }

  /**
   * get the GraphQL query to be sent to the endpoint.
   */
  get query(): DocumentNode {
    return this.compiledOperationCall;
  }
}
