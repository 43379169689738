import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';

import { Observable } from 'rxjs';

import { environment } from '@env/environment';
// import { TenantsService } from '../tenants.service';
import { ServiceBase } from '../service-base';
import { ApolloConfigurationService } from '../apollo-configuration.service';

import { AvailableScopesQuery, CurrentScopeQuery } from './queries';
import {
  SetCurrentScopeMutation,
  SetAvailableScopesMutation
} from './mutations';
import { Scope } from './models';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ApplicationStateService extends ServiceBase {
  clientName = environment.LocalCache.Key;

  private currentScopeQuery: CurrentScopeQuery;
  private availableScopesQuery: AvailableScopesQuery;

  constructor(
    apollo: Apollo,
    apolloConfig: ApolloConfigurationService,
    toastr: ToastrService
  ) {
    super(apollo, toastr);
    this.setupReusableQueries();

    // configure apollo link state
    apolloConfig.configureLinkState();

    // configure graphql endpoints using the current tokens
    apolloConfig.configureEndpoints();
    // this.tenantService.get()
  }

  setCurrentScope(scope: Scope): void {
    this.mutate(new SetCurrentScopeMutation(scope)).subscribe();
  }

  get currentScope$(): Observable<Scope> {
    return this.watchQuery(this.currentScopeQuery);
  }

  get scopes$(): Observable<Array<Scope>> {
    return this.watchQuery(this.availableScopesQuery);
  }

  private setAvailableScopes(userScopes: Array<Scope>) {
    const scopes = userScopes.map(s => ({ id: s.id, Name: s.Name }));

    return this.mutate(new SetAvailableScopesMutation(scopes));
  }

  private setupReusableQueries(): void {
    this.currentScopeQuery = new CurrentScopeQuery();
    this.availableScopesQuery = new AvailableScopesQuery();
  }
}
