import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment';
@Injectable({
  providedIn: 'root'
})
export class WorkflowSettingsService {
  settings: any;

  constructor(private http: HttpClient) {  }

  resolve(): Promise<any> {
    if (this.settings) {
      return Promise.resolve(this.settings);
    }
    if (!sessionStorage.getItem('tenant_id')) {
      return Promise.resolve(null);
    }

    return this.get(sessionStorage.getItem('tenant_id')).toPromise();
  }

  get(tenantId: string): Observable<any> {
    if (!tenantId) return of({});

    return this.http.get(`${environment.BASE_URL_BGC}/tenants/settings/${tenantId}/?type=workflow`);
  }
}
