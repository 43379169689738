// 3rd party libs
import { NgModule } from '@angular/core';
import { ApolloModule } from 'apollo-angular';
import { HttpLinkModule } from 'apollo-angular-link-http';
// servics
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { ApolloService } from '@app/core/services/apollo.service';

@NgModule({
  imports: [
    ApolloModule,
    HttpLinkModule
  ],
  providers: [
    LocalStorageService,
    ApolloService
  ]
})

export class CommonServicesModule {
  constructor() {}
}
