<bc-catch-messages [manager]="bcMessagesManager">
  <bc-message key="UNDEFINED_ERROR" i18n>Something went wrong. Please try login later.</bc-message>
  <bc-message key="EXPIRED_CODE" i18n>Expired code received for user.</bc-message>
  <bc-message key="INVALID_CODE" i18n>Invalid code or auth state for the user.</bc-message>
</bc-catch-messages>

<section class="confirmation confirmation__indent">
  <h1 class="confirmation__title" i18n="@@verificationCode">Verification code</h1>
  <div class="confirmation__form-wrap">
    <dx-form id="form" [formData]="confirmData" labelLocation="top">
      <dxi-item dataField="code">
        <dxo-label text="Verification code" i18n-text="@@verificationCode"></dxo-label>
        <dxi-validation-rule type="required"
                             i18n-mesage message="Verification code is required"></dxi-validation-rule>
        <dxi-validation-rule type="stringLength" [min]="6" [max]="6"
                             i18n-mesage message="Сode must be 6 digit"></dxi-validation-rule>
        <dxi-validation-rule type="numeric"
                             i18n-mesage message="Invalid entry, please enter a numerical value."></dxi-validation-rule>
      </dxi-item>
    </dx-form>

    <div class="dx-btn dx-btn__success bc-btn_block">
      <dx-button (onClick)="submit()"
                 i18n="@@verify" text="Verify"></dx-button>
    </div>

    <p class="auth__text" i18n> We have sent you a code on your phone, please enter it.</p>
  </div>
</section>

<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingVisible" [closeOnOutsideClick]="false"></dx-load-panel>
