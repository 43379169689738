// 3rd party libs
import { NgModule } from '@angular/core';
import { KycOverviewModalComponent } from './kyc-overview-modal/kyc-overview-modal.component';
import { StartKycModalComponent } from './start-kyc-modal/start-kyc-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BcCatchMessagesModule } from '../widgets/bc.catch-message/bc.catch-messages.module';
import { BcModalModule } from '../widgets/bc.modal/bc.modal.module';
import { BcMessagesModule } from '../widgets/bc.messages/bc.messages.module';
import { CommonModule } from '@angular/common';
import { ViewKycLogsModalComponent } from './view-kyc-logs-modal/view-kyc-logs-modal.component';
import { InlineHrefModule } from '../widgets/bc.inline-href/bc.inline-href.module';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxDataGridModule,
        BcCatchMessagesModule,
        InlineHrefModule,
        BcModalModule,
        BcMessagesModule,
    ],
    declarations: [
        KycOverviewModalComponent,
        StartKycModalComponent,
        ViewKycLogsModalComponent
    ],
    entryComponents: [
        KycOverviewModalComponent,
        StartKycModalComponent,
        ViewKycLogsModalComponent
    ],
})
export class ModalsModule { }
