import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular-link-http';
import { withClientState } from 'apollo-link-state';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';

import { environment } from '@env/environment';
import { resolvers } from './app-state/resolvers';
import { TokenHelper } from '@blancoservices/bc-auth-web';

@Injectable()
export class ApolloConfigurationService {
  cache: InMemoryCache;

  constructor(private apollo: Apollo, private httpLink: HttpLink) {
    this.cache = new InMemoryCache();
  }

  configureLinkState(): void {
    this.createClient(ApolloLink.from([this.getStateLink(this.cache)]), this.cache, environment.LocalCache.Key);
  }

  configureEndpoints(): void {
    const endpointsConfigs = Object.keys(environment.GraphQlEndpoints).map(k => environment.GraphQlEndpoints[k]);
    endpointsConfigs.forEach(config => {
      const http = this.httpLink.create({ uri: config.Url });

      this.createClient(
          setContext((_, { headers }) => ({
            headers: (headers || new HttpHeaders())
                    .append('authorization', TokenHelper.getAccessToken())
          })).concat(http),
        this.cache,
        config.Name
      );
    });
  }

  private getStateLink(memCache): any {
    return withClientState({
      cache: memCache,
      ...resolvers
    });
  }

  private createClient(link, cache, name): void {
    this.apollo.create({
      link,
      cache,
    }, name);
  }
}
